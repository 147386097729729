// extracted by mini-css-extract-plugin
export const hidden = "styles-module--hidden--2Q891";
export const container = "styles-module--container--TmIvq";
export const hero = "styles-module--hero--1ILrk";
export const backBtn = "styles-module--backBtn--1dEZl";
export const embeddedCard = "styles-module--embeddedCard--3PhQw";
export const left = "styles-module--left--3NV2V";
export const logo = "styles-module--logo--1C0bz";
export const name = "styles-module--name--1ErLA";
export const description = "styles-module--description--3vAdq";
export const right = "styles-module--right--2hc-n";
export const label = "styles-module--label--1vPOU";
export const items = "styles-module--items--2FJca";
export const top = "styles-module--top--kFImg";
export const bottom = "styles-module--bottom--3onbJ";
export const list = "styles-module--list--fYBLC";
export const sections = "styles-module--sections--1kp-9";
export const item = "styles-module--item--1IfK3";
export const texts = "styles-module--texts--3Xdoa";
export const title = "styles-module--title--1MYSl";
export const illustrations = "styles-module--illustrations--1QqME";