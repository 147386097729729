import * as React from "react"
import * as styles from './styles.module.scss'
import OurWork from "../../Models/OurWork"
import BackIcon from '../../../../images/icons/ic_btn_back.png'
import { navigate } from "gatsby";
import Layout from "../../../Common/Components/layouts/en";
import Seo from "../../../Common/Components/seo";

interface Props {
  work: OurWork;
}

const WorkDetailScreen = (props: Props) => {
  const { logo, title, description, coverImage } = props.work
  const { scopes, deliverables, technologies } = props.work
  const { sections } = props.work

  return (
    <Layout>
      <Seo title={`${title} | Aseven Indonesia Portfolios | Business Consulting and Software Solution`} />

      <div className={styles.container}>
        <div
          className={styles.hero}
          style={{
            background: `url(${coverImage})`
          }}
        >
          <div
            className={styles.backBtn}
            onClick={() => navigate('/')}
          >
            <img src={BackIcon} />
            <p>Kembali ke Halaman Awal</p>
          </div>

          <div className={styles.embeddedCard}>
            <div className={styles.left}>
              <img src={logo} className={styles.logo} alt={`Logo of ${title}`}/>

              <h2 className={styles.name}>
                {title}
              </h2>

              <p className={styles.description}>
                {description}
              </p>
            </div>

            <div className={styles.right}>
              <div className={styles.top}>
                <div>
                  <p className={styles.label}>
                    Scope
                  </p>
                  <div className={styles.items}>
                    {
                      scopes.map(scope => (
                        <p>{scope}</p>
                      ))
                    }
                  </div>
                </div>

                <div>
                  <p className={styles.label}>
                    Deliverables
                  </p>
                  <div className={styles.items}>
                    {
                      deliverables.map(deliverable => (
                        <p>{deliverable}</p>
                      ))
                    }
                  </div>
                </div>
              </div>

              <div className={styles.bottom}>
                <p className={styles.label}>
                  Technologies
                </p>

                <div className={styles.list}>
                  {
                    technologies.map(technology => (
                      <img src={technology.logo} alt={technology.name} />
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sections}>
          {
            sections.map(section => (
              <div className={styles.item}>
                <div className={styles.texts}>
                  <p className={styles.title}>
                    {section.title}
                  </p>

                  <p className={styles.description}>
                    {section.description}
                  </p>
                </div>

                <div className={styles.illustrations}>
                  {
                    section.photos.map(photo => (
                      <img
                        src={photo}
                        alt={`Illustration of ${title}`}
                        className={styles.illustration}
                      />
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Layout>
  );
};

export default WorkDetailScreen;